<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <h2 class="content-header-title float-start mb-0">
                        {{ event?.name }}
                    </h2>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 mb-2">
                <div class="col-12">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb float-end">
                            <li class="breadcrumb-item">
                                <router-link to="/event">Events</router-link>
                            </li>
                            <li class="breadcrumb-item active">{{ event?.name }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-body">
            <section id="dashboard-ecommerce">
                <div class="row match-height">
                    <div class="col-xl-12 col-md-12 col-12">
                        <div class="card card-congratulation-medal">
                            <div class="card-body">
                                <div class="row mb-1">
                                    <div class="d-flex col-12">
                                        <div class="float-start col-6">
                                            <div class="input-group input-group-merge w-50">
                                                <span class="input-group-text" id="basic-addon-search2"><vue-feather
                                                        type="search"></vue-feather></span>
                                                <input type="text" class="form-control" placeholder="Search..."
                                                    aria-label="Search..." aria-describedby="basic-addon-search2"
                                                    v-model="search" @keyup="searchData" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section id="ajax-datatable">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-datatable">
                                                    <table class="dt-column-search table table-responsive">
                                                        <thead>
                                                            <tr>
                                                                <template v-for="head in header" :key="head">
                                                                    <th>{{ head }}</th>
                                                                </template>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-show="list.length > 0" v-for="(li, index) in list"
                                                                :key="li.id">
                                                                <td>{{ index + paginationData.from }}</td>
                                                                <td>{{ li.main_villa?.booking_id }}</td>
                                                                <td>{{ li?.first_name + (li?.last_name ? ' ' + li?.last_name
                                                                    : '')
                                                                }}</td>
                                                                <td>{{ li?.mobile }}</td>
                                                            </tr>
                                                            <tr v-show="list.length === 0">
                                                                <td colspan="11" class="text-center">No Data Found</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <template v-for="head in header" :key="head">
                                                                    <th>{{ head }}</th>
                                                                </template>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                    <div class="row d-flex">
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6 showing_entry">
                                                                Showing {{ paginationData.from }} to
                                                                {{ paginationData.to }} of
                                                                {{ paginationData.total }} entries
                                                            </div>
                                                            <div class="col-6">
                                                                <Pagination :data="paginationData"
                                                                    @pagination-change-page="getResults"
                                                                    show-disabled="true" limit="3" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
    
<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';

export default {
    name: "Theater",
    components: {
        Pagination: LaravelVuePagination,
    },
    data() {
        return {
            id: this.$route.params.event_id,
            event: {},
            response: "",
            disabled: false,
            header: [],
            list: [],
            paginationData: {},
            search: null,
            token: localStorage.getItem('auth_token'),
        };
    },
    async mounted() {
        this.header = [
            "Sr.",
            "Booking ID",
            "Name",
            "Mobile"
        ];

        this.getEvent();
    },
    methods: {
        async getEvent() {
            await axios
                .get(process.env.VUE_APP_API_CUS_URL + "/get-events/" + this.id,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Accept': "application/json",
                        }
                    })
                .then((response) => {
                    if (response.data) {
                        this.event = response.data.data;
                        this.getResults();
                    }
                });
        },
        async getResults(page = 1) {
            var searchq = "";
            if (this.search) {
                searchq = "&search=" + this.search;
            }

            await axios
                .get(process.env.VUE_APP_API_CUS_URL + "/get-events-incoming/" + this.id + "?page=" + page + searchq,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Accept': "application/json",
                            "Authorization": `Bearer ${this.token}`
                        }
                    })
                .then((response) => {
                    this.paginationData = response.data.data;
                });
            this.list = this.paginationData.data;
        },
        dateResult() {
            const date = this.form.date;
            if (date.length > 0 && date.length != undefined) {
                date.map(() => {
                    this.form.from_date = moment(date[0]).format('YYYY-MM-DD')
                    this.form.to_date = moment(date[1]).format('YYYY-MM-DD')
                })
            } else {
                if (typeof date != undefined) {
                    this.form.from_date = moment(date).format('YYYY-MM-DD')
                    this.form.to_date = moment(date).format('YYYY-MM-DD')
                }
            }
        },
        async searchData() {
            this.getResults()
        },
        tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }
    },
};
</script>
<style scoped>
/* a {
      position: absolute;
      right: 15px;
      bottom: 15px;
      font-weight: bold;
      text-decoration: none;
      color: #00003a;
      font-size: 20px;
    } */


/*tag input style*/

.tag-input {
    width: 100%;
    border: 1px solid #D9DFE7;
    background: #fff;
    border-radius: 4px;
    font-size: 0.9em;
    min-height: 45px;
    box-sizing: border-box;
    padding: 0 10px;
    margin-bottom: 10px;
}

.tag-input__tag {
    height: 24px;
    color: white;
    float: left;
    font-size: 14px;
    margin-right: 10px;
    background-color: #7367f0;
    border-radius: 15px;
    margin-top: 10px;
    line-height: 24px;
    padding: 0 8px;
    transition: 0.1s ease background;
}

.tag-input__tag>span {
    cursor: pointer;
    opacity: 0.75;
    display: inline-block;
    margin-left: 8px;
}

.tag-input__text {
    border: none;
    outline: none;
    font-size: 1em;
    line-height: 40px;
    background: none;
}

.image_list_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.image_list_wrapper .image-box {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 104px;
    display: block;
    position: relative;
}

.delete-btn-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
}

.delete-btn-wrapper .delete-btn {
    color: red;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image_list_wrapper .image-box:hover .delete-btn-wrapper {
    display: flex;
}

/* del button */
.delete-btn {
    background: #7da741;
    color: white;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0px 3px 9px 0px black;
    border: 1px solid #2bb2cc;
    width: 30px !important;
    height: 30px !important;
}

.delete-btn i {
    color: white;
}

/* end */
</style>